const creationGalleryElem = document.querySelector('#creations-gallery')
const coursesGalleryElem = document.querySelector('#courses-gallery')

const openPicture = (e) => {
  const imgEl = document.querySelector(`#${e.target.id}`)
  if (imgEl) {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    imgEl.backupStyle = imgEl.style
    if (vw > 1024) {
      imgEl.style.width = '60%'
    }
    imgEl.style.position = 'fixed'
    imgEl.style.top = '50%'
    imgEl.style.left = '50%'
    imgEl.style.transform = 'translate(-50%, -50%)'
    imgEl.style.zIndex = 1
    imgEl.classList.add('img-is-open')
    imgEl.onclick = closePicture

    let backdrop = document.createElement('div')
    backdrop.id = 'backdrop'
    backdrop.style.position = 'fixed'
    backdrop.style.width = '100vw'
    backdrop.style.height = '100vh'
    backdrop.style.top = '0'
    backdrop.style.left = '0'
    backdrop.style.backgroundColor = '#000'
    backdrop.style.opacity = 0.5
    backdrop.style.zIndex = 0
    document.body.appendChild(backdrop)
    document.body.style.overflow = 'hidden'
  }
}

const closePicture = (e) => {
  const imgEl = document.querySelector(`#${e.target.id}`)
  if (imgEl) {
    imgEl.style = imgEl.backupStyle
    imgEl.style.cursor = 'pointer'
    imgEl.onclick = openPicture
    imgEl.classList.remove('img-is-open')
    document.querySelector('#backdrop').remove()
    document.body.style.overflow = 'auto'
  }
}

const loadGallery = (type = 'creations', nbItems = 4) => {
  let gelleryElem = creationGalleryElem

  if (type === 'courses') {
    gelleryElem = coursesGalleryElem
  }

  let childElem = gelleryElem.lastElementChild;
  while (childElem) {
    gelleryElem.removeChild(childElem);
    childElem = gelleryElem.lastElementChild;
  }
  for (let i = 1; i <= nbItems; i++) {
    let img = document.createElement('img')
    img.src = `./assets/img/gallery/${type}/${i}.jpg`
    img.alt = `${i}`
    img.id = `${type}-${i}`
    img.style.cursor = 'pointer'
    img.onclick = openPicture
    gelleryElem.appendChild(img)
  }
  if (nbItems > 5) {
    if (type === 'creations') {
      const button = document.querySelector('#showMoreCreations')
      button.style.display = 'none'
    } else if (type === 'courses') {
      const button = document.querySelector('#showMoreCourses')
      button.style.display = 'none'
    }
  }
}

loadGallery('creations', 4)
loadGallery('courses', 4)